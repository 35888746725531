<template>
  <section class="m-login m-scroll">
    <!-- 头部导航 -->
    <div class="header">
      <img src="@/assets/login/logo2@2x.png" alt="img" class="logo">
      <p class="tips">Login</p>
    </div>

    <div class="main">

      <!-- 账号登录 -->
      <div class="sign-password" v-show="sign == 0">
        <p>Tel/Email</p>
        <input v-model="account" type="text" placeholder="Enter phone or email">
        <p>Password</p>
        <div class="password-input">
          <input v-model="password" :type="type" placeholder="Enter your password">
          <img v-show="type == 'password'" @click="onToggle()" src="@/assets/login/icon_offbiyan@2x.png" alt="toggle">
          <img v-show="type == 'text'"     @click="onToggle()" src="@/assets/login/show.png" alt="">
        </div>
        <div class="to-code">
          <span @click="toggleSign(1)">Verification code login</span>
          <span @click="resetPassword()">Forget password</span>
        </div>
        <van-button :disabled="disabled" @click="handleLogin"  block round  color="linear-gradient(90deg, #2D77E0, #245FB3)">LOGIN</van-button>
        <p class="tips" style="margin-top: 10px" @click="$router.push('/en/setting/clause/privacy')">
          *Login means you have agreed to <span>Mooby Privacy Policy</span>
        </p>
        <van-button @click="toGuest"  block round hairline plain color="#2562B9" v-if="$route.query.bargain != 1 && flag">Guest login</van-button>
      </div>

      <!-- 验证码登录 -->
      <div class="sign-code" v-show="sign != 0">

        <div class="sign-tabs">
          <div class="tab-item" :class="{'tab-item-active':sign == 1}" @click="toggleSign(1)">Phone number</div>
          <div class="tab-item" :class="{'tab-item-active':sign == 2}" @click="toggleSign(2)">Email</div>
          <div class="tab-active" ref="tabActive"></div>
        </div>

        <div class="sign-tel" v-show="sign == 1">
          <p class="input-title">Tel</p>
          <input v-model="phone" type="text" placeholder="Enter phone number" class="input-reset">
          <p class="input-tips">*For new registers, a Mooby account will automatically create after verification. </p>
        </div>

        <!-- 邮箱登录 -->
        <div class="sign-tel" v-show="sign == 2">
          <p class="input-title">Email</p>
          <input v-model="email" type="text" placeholder="Enter e-mail" class="input-reset">
          <p class="input-tips">*For new registers, a Mooby account will automatically create after verification. </p>
        </div>

        <div class="to-code" @click="toggleSign(0)">Password login</div>
        <van-button  block round  color="linear-gradient(90deg, #2D77E0, #245FB3)" @click="loginCode()">LOGIN</van-button>
        <p class="tips" style="margin-top: 10px" @click="$router.push('/en/setting/clause/privacy')">
          *Login means you have agreed to <span>Mooby Privacy Policy</span>
        </p>
        <van-button @click="toGuest"  block round hairline plain color="#2562B9" v-if="$route.query.bargain != 1 && flag">Guest login</van-button>
      </div>

      <m-divider title="Login with" class="other" v-if="wxBrower"></m-divider>
      <div class="others" v-if="wxBrower">
        <!-- <img src="@/assets/login/icon_face@2x.png" alt="FB">
        <img src="@/assets/login/icon-ins@2x.png" alt="INS"> -->
        <img @click="wxLogin" src="@/assets/login/icon-weixin@2x.png" alt="Wechat" v-if="wxBrower">
      </div>
    </div>


  </section>
</template>

<script>
/* eslint-disable*/
import MDivider  from '@/components/en/m-divider.vue'
import { login, guest } from '@/api/zh/login'
import { getCoupon } from "@/api/en/night_market.js";
import { getAccountInfo } from "@/api/zh/mine";

export default {
  name:'Login',
  components:{ MDivider },
  data(){
    return {
      sign     : 1,    // 登录方式 0 账号登录 1 手机登录  2 邮箱登录
      account  : '',
      password : '',
      type     : 'password',
      phone    : '',
      email    : '',

      disabled : false,   // btn 点击之后禁用
      wxBrower : false,   // 是否是微信游览器
      flag: true
    }
  },

  methods:{
    // 切换密码 明文
    onToggle(){
      this.type = this.type == 'password' ? 'text' : 'password'
    },
    // 切换登录方式
    toggleSign(index){
      this.sign = index
      if(index != 0){
        let left =  (index-1)  * 42.13333
        this.$refs.tabActive.style.left = left + 'vw'
      }
    },

    // 忘记密码
    resetPassword() {
      if(this.account == "") {
        this.$notify({ type: 'warning', message: 'Please enter your account' });
        return false
      }else{
        let reg = /^[0-9]*$/
        if(reg.test(this.account)) {
            this.$router.push( {path: '/en/setting/identity', query: { phone: this.account,  email: ''}} )
        }else{
          this.$router.push( {path: '/en/setting/identity', query: { phone: '',  email: this.account}} )
        }
      }
    },


    // 密码登录
    handleLogin() {
      this.disabled = true
      let params = { 
        account  : this.account,
        password : this.password,
        night_token: '',
        is_coupon: window.localStorage.getItem("coupon") ? 1 : '',
      }
      if (localStorage.getItem('token2')) {
          params.night_token = localStorage.getItem('token2')
      }
      let regeamil = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
      if (localStorage.getItem('token2')) {
          params.night_token = localStorage.getItem('token2')
      }
      if (regeamil.test(this.account)) {
        params.account_type = 2
      }else {
        params.account_type = 1
      }
    login(params).then(res => {
      if(res) {
        localStorage.setItem('token', res.access_token)
        localStorage.setItem('token2', res.access_token)
        if (res.is_night && res.is_night == 1) {
          getCoupon();
        }
        this.$notify({ type: 'success', message: res.msg});
        getAccountInfo().then(res => {  
            if(res){
                localStorage.setItem('setPersonal',JSON.stringify(res.data)) 
                if(this.$route.query.email == 1) {
                  this.$router.push('/en/cart')
                } else if(this.$route.query.bargain == 1) {
                  this.$router.push('/en/bargain')
                } else if(this.$route.query.pay_title){
                  this.$router.push('/en')
                } else {
                  this.$router.go(-1)
                }
            }
        })
      }
    }).finally( () => {
      this.disabled = false
    })
    },


    // 验证码登录
    loginCode() {
      if(this.sign == 1) {
        let reg = /^[0-9]*$/
        if(!reg.test(this.phone) || this.phone == '') {
            this.$notify({ type: 'warning', message: 'Wrong phone number' });
            return false
        }
        if(this.$route.query.email == 1) {
          this.$router.push({path: '/en/code', query: {phone: this.phone,cart: this.$route.query.email}})
        } else {
          this.$router.push({path: '/en/code', query: {phone: this.phone, bargain: this.$route.query.bargain}})
        }
      }
      if(this.sign == 2) {
        /* eslint-disable */
        let reg = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/
        if(!reg.test(this.email) || this.email == '') {
            this.$notify({ type: 'warning', message: 'Wrong email' });
            return false
        }
        if(this.$route.query.email == 1) {
          this.$router.push({path: '/en/code', query: {email: this.email,cart: this.$route.query.email}})
        } else {
          this.$router.push({path: '/en/code', query: {email: this.email, bargain: this.$route.query.bargain}})
        }
      }
    },

    // 游客登录
    // 游客登录
    toGuest(){
      guest().then(res => {
        if(res) {
          localStorage.setItem('token', res.access_token)
          localStorage.setItem('token2', res.access_token)
          this.$notify({ type: 'success', message: res.msg});
          getAccountInfo().then(res => {  
              if(res){
                  localStorage.setItem('setPersonal',JSON.stringify(res.data)) 
                  if(this.$route.query.pay_title){
                    this.$router.push('/en')
                  } else
                  this.$router.go(-1)
              }
          })
        }
      })
    },


    // 微信登录
    wxLogin() {
        const redirect_uri   = encodeURIComponent('https://m.moobyyoho.com/en/login/other')
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7b2a33579e98879d&redirect_uri=' + redirect_uri + '&response_type=code&scope=snsapi_userinfo&state=wx#wechat_redirect'
    },

    // INS 登录
    insLogin() {
      const redirect_uri   = encodeURIComponent('https://m.moobyyoho.com/en/login/other')
      window.location.href = `https://api.instagram.com/oauth/authorize/?client_id=${this.CLIENT_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=user_profile,user_media&state=ins`;
    },

    // FB 初始化 请求登录
    fbInit() {
      FB.login( res => {
            this.fbCallback(res); //登录回调函数
      }, { scope: "email" } );
    },

    fbCallback(response) {
      const _this = this
      if (response.status === "connected") {

        var accessToken = response.authResponse.accessToken; //取得 accessToken
        //获取用户个人信息
        FB.api("/me?fields=name,first_name,last_name,email", function(response) {
          // /me为API指定的调用方法，用于获取登陆用户相关信息
          _this.fbData = response;
          if (response.email != null) {
            //获取用户头像
            FB.api( response.id + "/picture",
              "GET",
              { redirect: "false" },
              function(pic) {
                _this.fbData.pic = pic.data.url;
                _this.fbLogin();
              }
            );
          }
        });
      } else {
        this.$notify( { type: 'danger', message: 'FaceBook授权失败'} )
      }
    },
    fbLogin() {

    },
  },
  mounted() {
    // 异步初始化FB
    window.fbAsyncInit = function() {
      FB.init({
        appId: "738160799934904",
        cookie: true,
        xfbml: true,
        version: "v3.3"
      });
    }

  },
  created() {
    if(this.$route.query.pay_title) {
      localStorage.setItem("pay_titleF", this.$route.query.pay_title)
    }
    // 判断是否时微信游览器
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.wxBrower = true
    } else {
      this.wxBrower = false
    }

    // 加载 fb登录所需 js 文件 
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

  },
  beforeRouteEnter(to, from, next) {
    sessionStorage.setItem('preLink',from.fullPath)
    next(vm => {
        //因为当钩子执行前，组件实例还没被创建
        // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
        //当前组件的实例
        if(from.path == '/en/bargain') {
          vm.flag = false
        }
    });
  },
}
</script>

<style lang="less" scoped>
@import './login.less';
</style>